@media(max-width: 1279px)
{
	.main-header {
		height: 10rem;
	}
	.main-header .main-menu {
		width: 62rem;
		margin-top: 3.2rem;
	}
	.main-header .main-menu li a {
		font-size: 1.5rem;
	}
	.main-header .header-logo {
		width: 17rem;
	}
	.accueil {
		padding-top: 10rem;
	}
	.accueil .bandeau-item {
		height: 40rem;
	}
	.accueil .section .section-inner,
	.main-footer .footer-content {
		max-width: 99rem;
	}
	.accueil .section-2 .column-item,
	.accueil .section-3 .column-item {
		width: 32%;
	}
	.accueil .section-2 .column-item {
		padding: 2rem;
	}
	.accueil .section-3 .column-item {
		padding: 6rem 3rem;
	}
}

@media(max-width: 1024px)
{
	.main-header {
		height: 8.5rem;
	}
	.main-header .header-logo {
		width: 14rem;
	}
	.main-header .main-menu {
		display: none;
	}
	.hamburger {
		display: block;
	}
	.accueil {
		padding-top: 8.5rem;
	}
	.accueil .section-2 .column-item .column-item-photo {
		width: 20rem;
		height: 20rem;
		margin-bottom: 3rem;
	}
	.accueil .section-2 .column-item .column-item-nom {
		font-size: 2.4rem;
	}
	.accueil .section-2 .column-item .column-item-poste {
		margin-top: 1.5rem;
	}
	.accueil .section-2 .column-item .column-item-description {
		margin-top: 2rem;
	}
}

@media(max-width: 899px)
{
	.accueil .section {
		padding-top: 7rem;
		padding-bottom: 7rem;
	}
	.section-1 .section-inner-img {
		padding-top: 4rem;
	}
	.accueil .section-2 .column-wrap,
	.accueil .section-3 .column-wrap {
		margin-bottom: 0;
		flex-direction: column;
	}
	.accueil .section-2 .column-item,
	.accueil .section-3 .column-item {
		width: 100%;
		&:nth-of-type(2) {
			margin-top: 2rem;
		}
		&:nth-of-type(3) {
			margin-top: 2rem;
		}
	}
	.accueil .section-1 .section-inner-text,
	.accueil .section-1 .section-inner-img {
		width: 100%;
	}
	.accueil .section-1 .section-inner-img img:nth-of-type(odd) {
		border-left: 0;
	}
	.accueil .section-1 .section-inner-img img:nth-of-type(even) {
		border-right: 0;
	}
	.column-wrap {
		flex-direction: column;
	}
	.accueil .section-4 .column-item {
		width: 100%;
		&:nth-of-type(2) {
			margin-top: 5rem;
		}
	}
	.accueil .section-4 .column-item .column-item-inner {
		max-width: 100%;
	}
}

@media(max-width: 768px)
{
	h2, h2 a {
		font-size: 2.4rem;
		margin-bottom: 4rem;
	}
}

@media(max-width: 767px)
{
	form .form-group {
		width: 100%;
	}
}

@media(max-width: 599px)
{
	.main-header {
		height: 6rem;
	}
	.main-header .header-logo {
		width: 8rem;
	}
	.main-header .header-logo svg {
		width: 8rem;
		height: 4rem;
	}
	.hamburger {
		top: 1.7rem;
	}
	.accueil {
		padding-top: 6rem;
	}
	.accueil .section-1 .section-inner-img img {
		width: 100%;
		margin-left: 0;
		margin-right: 0;
		border-left: 0;
		border-right: 0;
	}
	.section-title,
	.section-title a {
		font-size: 1.8rem;
	}
	.accueil .section-2 .column-item .column-item-nom {
		font-size: 2rem;
	}
	.accueil .section-2 .column-item {
		padding: 2rem 0;
	}
	.accueil .map {
		height: 45rem;
	}
}
