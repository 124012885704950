.main-footer {
	background-color: $brand-secondary;

	.footer-top {
		@include centered-wrapper;
		width: 100%;
		overflow: hidden;
		padding-top: 1rem;
		text-align: center;
		padding-bottom: 1rem;

		p, a, button {
			font-size: 1.4rem;
			color: $brand-tertiary;
		}

		button, a {
			color: $brand-tertiary;

			&:hover, &:active, &:focus {
				color: $brand-primary;
			}
		}

		.mentions-legales-button {
			@include inline-block;
		}

		.mentions-legales-button + a {
			@include inline-block;
			padding-left: 2rem;

			&:before {
				content: " ";
				@include inline-block;
				position: absolute;
				top: .9rem;
				left: .6rem;
				width: .3rem;
				height: .3rem;
				border-radius: 50%;
				background-color: $brand-tertiary;
			}
		}

		.footer-right {
			@include inline-block;
			float: left;
		}

		.footer-left {
			@include inline-block;
			float: right;
		}
	}

	.footer-content {
		@include centered-wrapper;
		padding-top: 2rem;
		padding-bottom: 2rem;

		p, a {
			font-size: 1.2rem;
		}

		a {
			color: $brand-secondary;

			&:hover, &:active, &:focus {
				color: $brand-primary;
			}
		}
	}
}

.mentions-legales {
	display: none;

	&.openMentions {
		display: block;
	}

	.mentions-legales-inner {
		@include centered-wrapper;
		padding-top: 3rem;
		padding-bottom: 2rem;

		ul {
			padding-left: 2rem;
			padding-top: 0.5rem;
			padding-bottom: 1rem;
			li {
				list-style: none;
				color: $brand-tertiary;
			}
		}
	}

	p + h2 {
		margin-top: 2rem;
	}

	a, p {
		color: $brand-tertiary;
	}

	p {
		text-transform: uppercase;
	}
}
