.main-header {
	position: fixed;
	display: block;
	height: 12rem;
	width: 100%;
	z-index: 9999;
	padding: 1rem 2rem;
	background-color: black;
	box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.08);

	.main-header-wrapper {
		max-width: 110rem;
		width: 100%;
		height: auto;
		margin: auto;
		padding-left: 0;
		padding-right: 0;
	}

	.header-logo {
		@include inline-block;
		width: 21rem;
		height: auto;

		svg {
			width: 20rem;
			height: 10rem;
		}
	}

	.main-menu {
		@include inline-block;
		width: 61rem;
		float: right;
		margin-top: 4rem;

		ul {
			position: relative;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}

		li {
			list-style: none;
			font-family: $headerFont;

			a {
				font-size: 1.5rem;
				color: $brand-tertiary;
				text-decoration: none;
				text-transform: uppercase;

				&:hover, &:active, &:focus {
					color: $brand-primary;
				}

				&.active {
					color: $brand-primary;
				}

			}

			&.header-langue {
				font-weight: 900;
				color: $brand-tertiary;

				a {
					text-transform: initial;
				}
			}

			&:nth-of-type(1) a {
				color: $brand-primary;
			}
		}
	}
}

.hamburger {
	position: absolute;
	right: 2rem;
	top: 3rem;
	width: 3rem;
	height: 2.7rem;
	cursor: pointer;
	display: none;

	.hamburger-inner {
		display: block;
		margin-top: 1.2rem;
	}

	.hamburger-inner,
	.hamburger-inner:before,
	.hamburger-inner:after {
		position: absolute;
		right: 0;
		height: 0.3rem;
		width: 2.2rem;
		border-radius: 0.4rem;
		transition: width 0.2s;
		background-color: $brand-primary;
	}

	.hamburger-inner:before,
	.hamburger-inner:after {
		content: "";
		display: block;
		right: 0;
		background-color: $brand-primary;
	}

	.hamburger-inner:before {
		top: -0.7rem;
		width: 3rem;
	}

	.hamburger-inner:after {
		bottom: -0.7rem;
		width: 1.5rem;
	}

	&:hover {
		.hamburger-inner,
		.hamburger-inner:before,
		.hamburger-inner:after {
			width: 3rem;
		}
	}
}


