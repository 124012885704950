.accueil {
	padding-top: 12rem;

	.hero {
		@include block;
	}

	.section {
		padding-top: 5.5rem;
		padding-bottom: 6rem;

		.section-inner {
			@include centered-wrapper;
		}
	}

	.section-1 {
		.section-inner {
			overflow: hidden;
		}

		.section-inner-text,
		.section-inner-img {
			@include block;
			width: 50%;
			float: left;
		}

		.section-inner-text {
			padding-right: 2rem;
		}

		.section-inner-img {
			img {
				width: 50%;
				height: auto;
				float: left;
				border: .4rem solid white;
			}
		}
	}


	.slider {
		position: relative;
		overflow: hidden;
		width: 100%;

		ul {
			position: relative;
			margin: 0;
			padding: 0;
			width: 100%;
			list-style: none;
		}
	}

	a.control_prev,
	a.control_next {
		svg {
			position: absolute;
			display: block;
			top: 50%;
			width: auto;
			z-index: 999;
			height: auto;
			padding: 2rem;
			cursor: pointer;
			font-weight: 600;
			background: #2a2a2a;
			text-decoration: none;
			color: $brand-tertiary;
			transform: translateY(-50%);
		}

		&:hover {
			opacity: 1;
			-webkit-transition: all 0.2s ease;
		}
	}

	a.control_prev svg {
		left: 0;
	}

	a.control_next svg {
		right: 0;
	}

	.bandeau-item {
		@include block;
		height: 50rem;
		margin: 0;
		padding: 0;
		width: 100vw;
		float: left;
		text-align: center;
		background-size: cover;
		transition: all .2s ease;
		background-repeat: no-repeat;
		background-position: center center;

		&.bandeau-item-1 {
			background-image: url('../img/must-privilege-hero-2.jpg');
		}
		&.bandeau-item-2 {
			background-image: url('../img/must-privilege-hero.jpg');
		}
		&.bandeau-item-3 {
			background-image: url('../img/must-privilege-hero.jpg');
		}
	}

	.section-2 {
		background-color: #f3f3f3;

		.section-title:after {
			display: none;
		}

		.btn {
			margin-bottom: 8rem;
		}

		.column-wrap {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			width: 100%;
		}

		.column-item {
			@include block;
			width: 31%;
			text-align: center;
			padding: 2rem 3rem;
			border-radius: .4rem;

			&:hover, &:active, &:focus {
				.column-item-photo {
					box-shadow: 0 0 0 .3rem $brand-primary;
				}

				.column-item-rdv {
					background-color: $brand-primary;
				}
			}

			.column-item-photo {
				@include block;
				width: 22rem;
				height: 22rem;
				margin: auto;
				overflow: hidden;
				border-radius: 50%;
				margin-bottom: 4rem;
				transition: box-shadow .3s ease;
			}

			.column-item-nom {
				font-size: 2.6rem;
				font-weight: 900;
				font-family: $titleFont;
			}

			.column-item-poste {
				margin-top: 0;
				font-weight: 900;
				color: $brand-primary;
			}

			.column-item-description {
				margin-top: 3.2rem;

				&.show {
					.column-item-more {
						display: none;
					}

					.column-item-more-text {
						display: block;
					}
				}
			}

			.column-item-more {
				@include inline-block;
				cursor: pointer;
				margin-top: 1rem;
				color: $brand-secondary;

				text-decoration: underline;

				&:hover, &:active, &:focus {
					color: $brand-primary;
				}
			}

			.column-item-more-text {
				display: none;
				margin-top: 1rem;
			}

			.column-item-rdv {
				@include inline-block;
				width: 22rem;
				margin-top: 2rem;
				font-weight: 700;
				font-size: 1.5rem;
				border-radius: .2rem;
				padding: 1.5rem 1rem;
				font-family: $mainFont;
				color: $brand-tertiary;
				letter-spacing: 0.3rem;
				text-transform: uppercase;
				background-color: $brand-secondary;
				transition: background-color .3s ease;

				&:hover, &:active, &:focus {
					text-decoration: none;
				}
			}


			.btn {
				position: absolute;
				bottom: -4.7rem;
				left: 0;
				width: 100%;
				margin: 0;
				text-align: center;
			}
		}
	}

	.section-3 {
		background-color: $brand-secondary;

		.section-title span {
			color: $brand-tertiary;
		}

		.column-wrap {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			width: 100%;
			margin-top: 3.5rem;
		}

		.column-item {
			@include block;
			width: 31%;
			text-align: center;
			border-radius: 0.6rem;
			padding: 8.5rem 4.6rem;
			background-color: $brand-tertiary;

			.column-item-service {
				font-size: 2.6rem;
				font-weight: bold;
				text-transform: uppercase;
			}

			.column-item-detail {
				margin-top: 2.3rem;
			}
		}
	}

	.section-4 {
		.section-title {
			color: $brand-secondary;
		}

		p.section-title{
			margin-top: 4rem;

			&:after {
				display: none;
			}

			&+ p {
				margin-top: 2.6rem;
			}
		}

		.column-item {
			width: 50%;

			.column-item-inner {
				@include block;
				max-width: 39rem;
				width: 100%;
			}

			&.column-item-1 {
				padding-right: 2rem;
			}

			&.column-item-2 {
				display: flex;
				flex-direction: column;
				justify-content: center;
				background-color: black;
				padding: 5rem;

				img {
					max-width: 40rem;
					width: 100%;
					height: auto;
					margin: auto;
				}
			}
		}
	}

	.map {
		@include block;
		height: 60rem;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-image: url('../img/must-privilege-carte.jpg');
	}
}


