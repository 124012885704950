// FONT
$remInit: 10px;
$baseFontSize: 1.6rem;

$Monserrat: 'Montserrat', sans-serif;
$Lato: 'Lato', sans-serif;
$MavenPro: 'MavenPro', sans-serif;

$headerFont: $Monserrat;
$mainFont: $Lato;
$titleFont: $MavenPro;

$bodyBackground: white;

$brand-primary: #cfa952;
$brand-secondary: #262526;
$brand-tertiary: #ffffff;



