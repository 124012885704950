::-webkit-selection {
	background: $brand-primary;
	color: white;
}
::-moz-selection {
	background: $brand-primary;
	color: white;
}
::-ms-selection {
	background: $brand-primary;
	color: white;
}
::-o-selection {
	background: $brand-primary;
	color: white;
}
::selection {
	background: $brand-primary;
	color: white;
}

body {
	color: $brand-tertiary;
	font-family: $mainFont;
	background-color: white;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	margin-top: 0;
	color: inherit;
	font-weight: 500;
	line-height: 1.1;
	font-family: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0;
	font-family: $Lato;
}


.clearfix::after {
	content: " ";
	display: block;
	height: 0;
	clear: both;
}

button {
	cursor: pointer;
}

button,
input[type="button"],
input[type="submit"] {
	padding: 0;
	border: none;
	box-shadow: none;
	border-radius: 0;
	text-shadow: none;
	background-color: transparent;

	&:hover, &:active, &:focus {
		box-shadow: none;
		text-decoration: none;
	}
}


ul, ol {
	margin: 0;
	list-style: none;

	li {
		list-style: inside;
	}
}

ul {
	padding-left: 0;
	margin-bottom: 1rem;
}

p + ul {
	margin-top: 1rem;
}

h1, ul, li, p, a {
	line-height: 1.5;
	font-size: 1.5rem;
	color: $brand-secondary;
}

p {
	margin: 0;
}

h1 {
	font-family: $mainFont;
}

.section-title {
	&, & a {
		line-height: 1.3;
		font-weight: 700;
		font-size: 2.6rem;
		color: $brand-primary;
		margin-bottom: 1.5rem;
		letter-spacing: .2rem;
		text-transform: uppercase;
	}

	&:after {
		content: "";
		display: block;
		width: 15.5rem;
		height: .4rem;
		margin-top: .8rem;
		background-color: $brand-primary;
	}

	span {
		color: $brand-secondary;
	}
}

a {
	color: $brand-primary;
	transition: background-color .3s ease;

	&:hover, &:active, &:focus {
		text-decoration: underline;
		color: lighter($brand-primary,10%);
	}

	&.btn {
		display: inline-block;
		font-size: 2rem;
		text-decoration: none;
		font-family: $mainFont;
		color: $brand-secondary;
		background-color: black;
		text-transform: uppercase;
		padding: 1rem 2.5rem 1.2rem;

		span {
			margin-left: 1rem;
		}

		&:hover, &:active, &:focus {
			text-decoration: none;
			color: $brand-secondary;
			background-color: $brand-primary;
		}
	}
}

p + .btn {
	margin-top: 4.5rem;
}

p + p {
	margin-top: 2rem;
}

p + h2,
p + h3,
p + h4,
p + h5 {
	margin-top: 5rem;
}


.column-wrap {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
}


#page {
	transition: transform 0.3s;
}

.bloc-title,
.entry-title,
.page-title {
	margin: 0;
	line-height: 1;
	font-size: 3.9rem;
	font-weight: bold;
	margin-top: 3.5rem;
	margin-bottom: 4rem;
	color: $brand-primary;
}

.menu-mobile {
	@include fixed-block;
	right: -3rem;
	top: 0;
	bottom: 0;
	width: 32rem;
	height: 100%;
	min-height: 100vh;
	visibility: hidden;
	background-color: black;
	transition: transform 0.3s;
	transform: translate( 32rem );
	box-shadow: 1rem 0 1rem 2rem rgba(0,0,0,0.3);

	.mobile-fermeture {
		position: absolute;
		left: 1rem;
		top: 2.2rem;
		width: 4rem;
		cursor: pointer;
		font-size: 4rem;
		text-align: center;
		font-weight: normal;
		color: $brand-primary;
		transition: all .3s ease;

		&:hover, &:active, &:focus {
			transform: rotateZ(45deg);
		}
	}

	.mobile-logo {
		@include auto-block;
		width: 14rem;
		height: 7rem;
		margin-top: 3rem;
		text-align: center;

		svg {
			width: 14rem;
			height: 7rem;
		}
	}

	ul {
		padding: 0;
		margin: 3rem 0 5rem;

		a {
			display: block;
			color: white;
			font-size: 1.2rem;
			text-align: center;
			padding: 0.7rem 2rem;

			&:hover, &:active, &:focus {
				color: $brand-primary;

				svg path {
					fill: $brand-primary;
				}
			}
		}

		li {
			position: relative;
			list-style: none;
			text-transform: uppercase;

			a {
				line-height: 1.6rem;
				font-size: 1.5rem;
			}

			&:last-child {
				border-bottom: none;

				a {
					border-bottom: none;
				}
			}

			&.herader-langue {
				text-align: center;

				a {
					display: inline-block;
				}
			}
		}

		svg {
			width: 3rem;
			height: 3rem;

			path {
				fill: white;
			}
		}
	}

	.mobile-tel {
		@include block;
		width: 17.3rem;
		color: white;
		margin: auto;
		font-size: 1.7rem;
		border-radius: 4rem;
		background-color: $brand-primary;
		padding: 1.2rem 1.3rem 1.2rem 4rem;

		svg {
			position: absolute;
			top: 1.3rem;
			left: 1.6rem;
			width: 1.4rem;
			height: 1.8rem;
		}

		&:hover, &:active, &:focus {
			background-color: lighten($brand-primary, 7%);
		}
	}
}

.openMenu{
	.menu-mobile{
		transform: translate( -3rem );
		visibility: visible;
	}
	#page{
		transform: translate( -32rem );
	}
}
